import React from "react"
import Select from "./Select"
import { languagesOptions } from "../utils"

const LanguageSelect = ({value, className}) => {
  // let defaultval = valuelanguagesOptions.find((item) => item.value === value)
  return (
    <Select
      options={languagesOptions}
      name="language"
      label="Preferred Language"
      defaultValue={value ? value : languagesOptions[2].value}
      className={className}
    />
  )
}

export default LanguageSelect
